import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <g transform="translate(25.000000, 27.000000)">
          <path
            d="M 9.24 36 L 0 36 Q 0.332 36 0.663 35.476 A 3.614 3.614 0 0 0 0.84 35.16 A 2.914 2.914 0 0 0 1.043 34.602 Q 1.205 33.989 1.246 33.065 A 14.989 14.989 0 0 0 1.26 32.4 L 1.26 3.6 Q 1.26 1.68 0.84 0.87 A 3.765 3.765 0 0 0 0.656 0.552 Q 0.328 0.047 0 0 L 33.78 0 L 33.78 1.2 L 34.98 6.96 Q 33.492 6.305 29.762 5.562 A 82.775 82.775 0 0 0 29.7 5.55 A 35.417 35.417 0 0 0 26.371 5.064 Q 24.733 4.899 22.881 4.837 A 68.873 68.873 0 0 0 20.58 4.8 L 10.44 4.8 Q 9.033 4.8 8.524 4.965 A 0.667 0.667 0 0 0 8.31 5.07 A 0.578 0.578 0 0 0 8.159 5.281 Q 8.003 5.622 7.983 6.37 A 8.584 8.584 0 0 0 7.98 6.6 L 7.98 13.44 Q 7.98 14.76 8.31 15 A 0.7 0.7 0 0 0 8.519 15.093 Q 9.013 15.239 10.358 15.24 A 25.311 25.311 0 0 0 10.38 15.24 L 20.28 15.24 A 51.461 51.461 0 0 0 22.323 15.202 Q 24.358 15.121 25.795 14.867 A 15.264 15.264 0 0 0 25.89 14.85 A 25.379 25.379 0 0 0 26.752 14.678 Q 28.164 14.368 28.68 14.04 A 52.782 52.782 0 0 0 28.492 14.667 Q 28.328 15.225 28.14 15.9 Q 27.84 16.98 27.66 18.06 Q 27.48 19.14 27.48 20.04 L 10.38 20.04 A 22.717 22.717 0 0 0 9.777 20.047 Q 8.599 20.079 8.32 20.244 A 0.347 0.347 0 0 0 8.31 20.25 Q 7.98 20.46 7.98 21.84 L 7.98 32.4 A 14.829 14.829 0 0 0 8.006 33.317 Q 8.061 34.193 8.229 34.754 A 2.516 2.516 0 0 0 8.4 35.19 Q 8.687 35.743 8.973 35.918 A 0.507 0.507 0 0 0 9.24 36 Z"
            fill="currentColor"
          />
        </g>
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
